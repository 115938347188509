import Button from '@common/Button';
import CommonForm from '@common/CommonForm2';
import Dialog from '@common/Dialog';
import Store from '@data/Store';
import KnowledgeBase from '@images/svg-icon/KnowledgeBase';
import { Grid } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import classnames from 'classnames';
import moment from 'moment-timezone';
import React, { Fragment, useEffect, useReducer, useState } from 'react';
import { Tooltip } from 'react-tippy';
import { applyTimezoneOnHTML } from '../../common/Timezone';
import { getChannelsService } from '../ChannelManagement/Channel.services';
import SolutionContainer from '../solution/SolutionContainer';
import Avaliation from './Avaliation';
import './DialogForm.css';
const crypto = require('crypto');
import { translateFromPtBR } from '@common/languages/Dictionary';
import { concatDateHour } from '../../util/dateTime';


const decryp = (aMsg = '') => {
  try {
    var aSecret = 'Orch3str0!@forever!';

    aMsg = aMsg.replace(/\s/g, '');
    let decipher, tRet;
    decipher = crypto.createDecipher('aes-256-cbc', aSecret);
    tRet = decipher.update(
      (aMsg || '').replace(/\s/g, '+'),
      'base64',
      'utf8'
    );
    tRet += decipher.final('utf8');

    return tRet;
  } catch (e) {
    return {};
  }
};

export default function ({
  open,
  onClose,
  store,
  action,
  APIEndpoints,
  values,
  onFormSubmitTab,
  ondonwFile,
}) {
  let {
    users,
    tickets_types_cat,
    chiefs,
    groupstech,
    groups,
    language,
    taskcatalog,
    priorities,
    impacts,
    urgencies,
    slas,

    view,
  } = store;

  const params = JSON.parse(decryp(sessionStorage.getItem('params')));

  const reducer = (state, value) => ({ ...state, ...value });
  let [anchorEl, setAnchoEl] = useState(null);
  let open_ = !!anchorEl;
  let [techname, setTechname] = useState('');
  const handleClick = (event) => setAnchoEl(event.currentTarget);
  let [ds, SetDs] = useState(true);

  let [formstemplate, setFormsTemplate] = useState([]);

  let [catalogs, setCatalogs] = useState([]);
  let [categories, setCategories] = useState([]);

  let [servies, setServices] = useState([]);

  const handleClose = () => setAnchoEl(null);
  const [forms, setForms] = useState([]);
  const [templatesForm, setTemplateForms] = useState([]);
  let [form1, setForms1] = useState(null);
  let [form2, setForms2] = useState(null);
  let [form3, setForms3] = useState(null);
  let [form4, setForms4] = useState(null);
  let [form5, setForms5] = useState(null);
  let [channel, setChannel] = useState([]);
  let [userDetail, setuserDetail] = useState({});
  let [contracts, setContracts] = useState([]);
  let [companies, setCompanies] = useState([]);
  let [solicitantGlobal, setSolicitantGlobal] = useState(false);
  let [ticketavaliation, setTicketavaliation] = useState(false);
  let [deviceOptions, setDeviceOptions] = useState([]);
  let [departmentOptions, setdepartmentOptions] = useState([]);
  let [locationOptions, setLocationOptions] = useState([]);
  let [responsibleOptions, setresponsibleOptions] = useState([]);
  const [openKnowledge, setOpenKnowledge] = useState(false);
  let [groupcat, setgroupcat] = useState([]);
  let [techGroupOptions, settechGroupOptions] = useState([]);
  let [solicitantOptions, SetsolicitantOptions] = useState([]);
  let [cleanValueSolicitant, setCleanValueSolicitant] = useState(false);
  const [attachIsRequired, setAttachIsRequired] = useState(false);
  const [attachmentError, setAttachmentError] = useState(false);
  const [knowledgeBaseSubcategory, setKnowledgeBaseSubcategory] = useState(null);
  const [totalGroupDocumentedCrisis, setTotalGroupDocumentedCrisis] = useState(0);
  let [enableTypeTicketCrisis, setEnableTypeTicketCrisis] = useState(false);

  //Variáveis para o formulário
  const [companyId, setCompanyId] = useState(null);
  const [requesterId, setRequesterId] = useState(null);
  const [subdptName, setSubdptName] = useState(language.SUBDEPARTMENT);
  const [dptName, setDptName] = useState(language.DEPARTMENT);
  const [chieftDptName, setChieftDptName] = useState(language.DEPARTMENT_BOSS);
  const [companyName, setCompanyName] = useState(language.COMPANY);
  const [allDptName, setAllDptName] = useState(language.DEPARTMENT);
  const [multiDepartment, setMultiDepartment] = useState([]);
  const [chiefSubDptName, setChiefSubDptName] = useState(
    language.SUBDEPARTMENT_BOSS
  );
  const [allChiefName, setAllChieftName] = useState(language.BOSS);
  const [keepOriginalCatalog, setKeepOriginalCatalog] = useState({
    keep: true,
  });
  const [generalParams, setGeneralParams] = useState([]);
  let [form_, setForm_] = useReducer(reducer, {
    company: '',
    contract: '',
    category: '',
    catalog: '',
    user: '',
    device: '',

    catalog_task: '',
    id_grouptech: 0,
    devicesUser: [],
    categoryOptions: [{ value: 0, label: '' }],
    iddep: null,
  });
  const [isProcessingSave, setIsProcessingSave] = useState(false);
  const initialState = {};
  const [json_template_solicitation, updateState] = useReducer(
    (state, updates) => ({
      ...state,
      ...updates,
    }),
    initialState
  );

  const [templateFormOriginal, setTemplateFormOriginal] = useState('');

  let [form, setForm] = useReducer(reducer, {
    type_tickets: null,
    fk_id_company: null,
    fk_id_device: null,
    devices: [],
    namesponsor: '',
    fields: [],
    fk_id_contract: null,
    fk_id_user: null,
    fk_id_category: null,
    fk_id_catalog_service: null,
    fk_id_catalog_task: null,
    fk_id_group_tech: null,
    fk_id_location: null,
    fk_id_tech: null,
    fk_id_department: null,
    fk_id_user_sponsor: null,
    fk_id_priority: null,
    fk_id_urgency: null,
    fk_id_channel: null,
    subject: null,
    fk_id_impact: null,
    fk_id_complexity: null,
    recurrent: 1,
    description: null,
    fk_id_request: null,
    files: [],
    filesrequest: [],
    ticketArray: undefined,
    req: [],
    type: '',
    template: null,
    dt_recurrence: null,
    time_recurrence: null,
    json_template_solicitation: {},
    documented_crisis: false
  });

  useEffect(() => {
    checkIfCurrentUserCanCreateCrisisTicket();
    action
      .execute('post', APIEndpoints.USER + `/${Store.getUserLoged().id}/params`, '', { documented_crisis: true })
      .then((result) => {
        const { data } = result;
        setTotalGroupDocumentedCrisis(data ? parseInt(data[0].count) : 0)
      });
  }, [generalParams]);


  console.log("totalGroupDocumentedCrisis", totalGroupDocumentedCrisis)

  useEffect(() => {
    if (subdptName != 'Subdepartamento') {
      action
        .execute(
          'post',
          APIEndpoints.DEPARTMENT + '/getDepartmentBySubName/',
          '',
          { name: subdptName }
        )
        .then((e) => {
          if (e.data.length > 0) {
            updateState({ departments_var: e.data[0].name });
            setDptName(e.data[0].name);
          } else {
            updateState({ departments_var: '' });
            setDptName(language.DEPARTMENT);
          }
        });
    }

    if (companyId && requesterId) {
      categoryByCompanyRequester();
    }
  }, [subdptName, companyId, requesterId]);

  useEffect(() => {
    if (companyId) {
      action
        .execute(
          'get',
          APIEndpoints.LOCATION + `/company/${companyId}`
        )
        .then((result) => {
          if (result.data.length > 0) {
            setLocationOptions(result.data.map(item => ({
              label: item.name,
              value: item.id_location
            })));
          } else {
            setLocationOptions([]);
          }
        });
    }
  }, [companyId])


  useEffect(() => {
    if (dptName != 'Departamento') {
      action
        .execute('post', APIEndpoints.COMPANY + '/getCompanyByDptName/', '', {
          name: dptName,
        })
        .then((e) => {
          if (e.data.length > 0) {
            setCompanyName(e.data[0].name);
            updateState({ companies_var: e.data[0].name });
          } else {
            setCompanyName(language.COMPANY);
            updateState({ companies_var: '' });
          }
        });

      action
        .execute('post', APIEndpoints.DEPARTMENT + '/getChiefByDptName/', '', {
          name: dptName,
        })
        .then((e) => {
          if (e.data.length > 0) {
            setChieftDptName(e.data[0].name);
            updateState({ chieftdpt_var: e.data[0].name });
          } else {
            setChieftDptName(language.DEPARTMENT_BOSS);
            updateState({ chieftdpt_var: '' });
          }
        });
    }
  }, [dptName]);

  useEffect(() => {
    if (templatesForm && templatesForm.length > 0) {
      let c = 0;
      let item = templatesForm.map((obj) => {
        c = c + 1;
        let id = 'ID' + c;
        return (
          <div className="contain">
            <div className="itemTicketDetail">
              <p style={{ color: '#888080' }} id={id}>
                {formshtml(decryp(obj.html))}
              </p>
            </div>
            <div style={{ marginTop: '80px' }} />
            <div>
              {obj.user_up != null
                ? language.LAST_UPDATE +
                obj.user_up +
                '-' +
                moment(obj.dt_up).format('DD-MM-YY HH:mm:ss')
                : null}
            </div>
            <hr />
          </div>
        );
      });
      try {
        setForms(item[0]);
      } catch (e) { }
    }
  }, [dptName, chieftDptName, subdptName, companyName]);

  const checkIfCurrentUserCanCreateCrisisTicket = () => {
    const currentUser = store.getUserLoged();
    const enabledCrisisInGeneralParams = generalParams.enable_crisis_ticket_type;
    const isRoleToEditCrisis = currentUser.role !== 'solicitant';

    if (!enabledCrisisInGeneralParams || !isRoleToEditCrisis) {
      setEnableTypeTicketCrisis(false);
    } else {
      action
        .execute('get', APIEndpoints.TICKET + '/validcrisis/0')
        .then((result) => {
          const { data } = result || {};
          setEnableTypeTicketCrisis(data && data.hasGroupCrisis);
        });
    }
  }

  // Verificar carregamento de dados relacionados ao campos dinâmicos no formulário de tickets.

  const formshtml = (t) => {
    const userLogin = Store.getUserLoged();
    const timezone = userLogin?.timezone?.value;
    /**
     * Added the timezone of the last user who updated the form.
     * This field is used to calculate the new time for the datetime field.
     */
    let data = [<input id="timezone-last-update-user" type="hidden" value={timezone} />];
    let names_to_scape = [];

    t.split('<div').map((i, key) => {
      const required = (i || '').indexOf('formbuilder-required') > -1;
      const labelMatch = (i || '').match(/label=\"([^\"]+)\"/);
      const label = labelMatch ? labelMatch[1] : undefined;
      const addEmptyOption = i.indexOf('addemptyoption') > -1 || i.indexOf('add-empty-option') > -1;

      if (addEmptyOption && i.indexOf('<select') > -1) {
        i.replace(/>Selecione</, function (match) {
          let originalText = match.slice(1, -1);
          let newText = translateFromPtBR(originalText, language);
          return `>${newText}<`;
        });
      }

      if (i.indexOf('companies_var') > -1 || i.indexOf('fieldType="companies"') > -1) {
        data.push(
          <div separator="true" id="companies_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ companies_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'companies_var',
                  label: label || language.COMPANY,
                  showEmptyFilter: addEmptyOption,
                  method: 'POST',
                  route: `${APIEndpoints.COMPANY}/filter`,
                  fieldquery: 'search',
                  textlabel: label || language.COMPANY,
                  textinit: '',
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: companyName,
                  routeAll: `${APIEndpoints.COMPANY}/all?${params[0].list_only_customer_type_companies ? 'type=1&' : ''}`,
                  methodGetAll: 'POST',
                  routeGetCount: `${APIEndpoints.COMPANY}/count`,
                  detail: true,
                  visible: true,
                  required,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_company',
                  // value:
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('locations_var') > -1 || i.indexOf('fieldType="locations"') > -1) {
        data.push(
          <div separator="true" id="locations_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ locations_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'locations_var',
                  label: label || language.LOCATION,
                  method: 'POST',
                  showEmptyFilter: addEmptyOption,
                  route: `${APIEndpoints.LOCATION}/filter`,
                  fieldquery: 'search',
                  textlabel: label || language.LOCATION,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  routeAll: `${APIEndpoints.LOCATION}/filter?`,
                  methodGetAll: 'POST',
                  detail: true,
                  visible: true,
                  required,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_location',
                  // value:
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('allusers_var') > -1 || i.indexOf('fieldType="allusers"') > -1) {
        data.push(
          <div separator="true" id="allusers_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ allusers_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'allusers_var',
                  label: '',
                  showEmptyFilter: addEmptyOption,
                  method: 'POST',
                  route: `${APIEndpoints.USER}/filter`,
                  fieldquery: 'search',
                  textlabel: label || language.USER,
                  textinit: '',
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: 'Usuário',
                  routeAll: `${APIEndpoints.USER}/filter/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${APIEndpoints.USER}/active/count/all`,
                  detail: true,
                  visible: true,
                  required,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_user',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('hours_var') > -1) {
        let nameField = 'hours_var';
        try {
          const regex = /<p(.*?)<\/p>/;
          const textWithoutTags = i.match(regex);
          nameField = textWithoutTags[1].split('|')[1];
        } catch (e) { }
        data.push(
          <div separator="true" id="hours_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ hours: value });
              }}
              fields={[
                {
                  col: 12,
                  name: nameField,
                  label: language.HOURS,
                  type: 'time',
                  format: '##:##',
                  required
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('hidden_var') > -1) {
        let value_field = '';
        let name_field = '';
        try {
          const regex = /<p(.*?)<\/p>/;
          const textWithoutTags = i.match(regex);
          name_field = textWithoutTags[1].split('|')[1];
          value_field = textWithoutTags[1].split('|')[2];
        } catch (e) { }
        const html_field = `<div hidden separator="true"><input value = ${value_field} name = ${name_field} /></div>`;
        data.push(<div dangerouslySetInnerHTML={{ __html: html_field }} />);
      } else if (i.indexOf('departments_var') > -1 || i.indexOf('fieldType="departments"') > -1) {
        data.push(
          <div separator="true" id="departments_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ departments_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'departments',
                  showEmptyFilter: addEmptyOption,
                  label: label || language.DEPARTMENTS,
                  textlabel: label || language.DEPARTMENTS,
                  method: 'POST',
                  route: `${APIEndpoints.DEPARTMENT}/filter/`,
                  routeGetCount: `${APIEndpoints.DEPARTMENT}/filter?count=0`,
                  fieldquery: 'search',
                  textinit: '',
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: dptName,
                  routeAll: `${APIEndpoints.DEPARTMENT}/filter?`,
                  methodGetAll: 'POST',
                  required,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('subsdpt_var') > -1 || i.indexOf('fieldType="subsdpt"') > -1) {
        data.push(
          <div separator="true" id="subsdpt_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ subdepartments_var: value });
                setSubdptName(value);
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'subdepartments',
                  showEmptyFilter: addEmptyOption,
                  label: label || language.SUBDEPARTMENT,
                  textlabel: label || language.SUBDEPARTMENT,
                  method: 'POST',
                  filterAll: { fk_id_department: 1 },
                  filter: { fk_id_department: 1 },
                  route: `${APIEndpoints.DEPARTMENT}/filter/`,
                  routeGetCount: `${APIEndpoints.DEPARTMENT}/filter?count=0`,
                  fieldquery: 'search',
                  textinit: '',
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${APIEndpoints.DEPARTMENT}/filter?`,
                  methodGetAll: 'POST',
                  required,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('contracts_var') > -1 || i.indexOf('fieldType="contracts"') > -1) {
        data.push(
          <div separator="true" id="contracts_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ contracts_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'contracts_var',
                  label: language.CONTRACTS,
                  method: 'POST',
                  showEmptyFilter: addEmptyOption,
                  route: `${APIEndpoints.CONTRACT}/filter`,
                  fieldquery: 'search',
                  textlabel: label || language.CONTRACT,
                  textinit: '',
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${APIEndpoints.CONTRACT}/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${APIEndpoints.CONTRACT}/count?`,
                  textlabel: language.CONTRACTS,
                  disablePortal: true,
                  showId: true,
                  required,
                  idFieldToShow: 'id_contract',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('chieftdpt_var') > -1 || i.indexOf('fieldType="chieftdpt"') > -1) {
        data.push(
          <div separator="true" id="chieftdpt_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ chieftdpt_var: value });
                setChieftDptName(value);
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'boss_dpt',
                  showEmptyFilter: addEmptyOption,
                  label: label || language.DEPARTMENT_BOSS,
                  textlabel: label || language.DEPARTMENT_BOSS,
                  method: 'POST',
                  filter: { fk_id_company: 1 },
                  route: `${APIEndpoints.USER}/filter`,
                  routeGetCount: `${APIEndpoints.USER}/active/count/all`,
                  fieldquery: 'search',
                  textinit: '',
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: chieftDptName,
                  routeAll: `${APIEndpoints.USER}/filter/all?`,
                  filterAll: 0,
                  methodGetAll: 'POST',
                  detail: true,
                  visible: true,
                  required,
                  disablePortal: true,
                },
              ]}
            />
          </div>
        );
      } else {
        if (i.length > 1) {
          //é preciso eliminar quaisquer trechos que não sejam úteis, caso contrario, esse template ficará maior a cada vez que for editado na tela, podendo travar o navegador do usuário
          let g = '';
          let has_names_to_scape = 'hours_var';
          names_to_scape.map((name) => {
            if (i.indexOf(name) > -1 && name !== 'hours_var') {
              has_names_to_scape = name;
            }
          });
          if (i.indexOf('ql-tooltip ql-hidden') > -1) {
            g = `<div style = "display : none" ${i}`;
          } else if (
            i.indexOf('ql-preview') > -1 ||
            i.indexOf('ql-clipboard') > -1 ||
            i.indexOf('ql-snow') > -1
          ) {
            g = `<div  ${i}`;
          } else if (i.indexOf('ql-editor') > -1) {
            g = `<div style = "border: 1px solid #629daa80; min-height : 30px; border-radius : 0px 0px 10px 10px" ${i}`;
          } else if (
            i.indexOf('id="boss_dpt"') > -1 ||
            i.indexOf('id="boss_dpt-label"') > -1 ||
            i.indexOf('id="boss_sub_dpt"') > -1 ||
            i.indexOf('id="boss_sub_dpt-label"') > -1 ||
            i.indexOf('id="all_boss_dpt"') > -1 ||
            i.indexOf('id="all_boss_dpt-label"') > -1 ||
            i.indexOf('id="subdepartments"') > -1 ||
            i.indexOf('id="subdepartments-label"') > -1 ||
            i.indexOf('id="departments"') > -1 ||
            i.indexOf('id="departments-label"') > -1 ||
            i.indexOf('id="all_departments-label"') > -1 ||
            i.indexOf('id="all_departments"') > -1 ||
            i.indexOf('id="allusers_var"') > -1 ||
            i.indexOf('id="boss"') > -1 ||
            i.indexOf(' *</span>') > -1 ||
            i.indexOf('class="CommonForm2"') > -1 ||
            i.indexOf('Autocomplete') > -1 ||
            i.indexOf('hours_var') > -1 ||
            // i.indexOf('>Usuário<') > -1 ||
            i.indexOf(has_names_to_scape) > -1 ||
            //i.indexOf('>Horas</label>') > -1 ||
            i.indexOf('type="button"') > -1
          ) {
          } else {
            g = `<div class = "template-dinamic" ${i}`;
          }
          const div_with_no_children = /<div [^>]+>(.*?)<\/div>/g.exec(g);
          if (
            div_with_no_children &&
            div_with_no_children.length > 1 &&
            div_with_no_children[1] == ''
          ) {
            g = '';
          }

          g = g.replace(
            /<div class = "template-dinamic"  class="template-dinamic"><\/div><\/div>/g,
            ''
          );
          g = g.replace(/<div class = "template-dinamic" >/g, '');
          g = g.replace(/<div><\/div>/g, '');
          g = g.replace(/<div><\/div>/g, '');
          g = g.replace(
            /<div><div class="template-dinamic"><\/div><\/div>/g,
            ''
          );

          if (g.length > 0) {
            data.push(<div dangerouslySetInnerHTML={{ __html: g }} />);
          }
        }
      }
    });
    return data;
  };

  const formshtmlWithEdit = (t) => {
    const userLogin = Store.getUserLoged();
    const timezone = userLogin?.timezone?.value;
    /**
     * Added the timezone of the last user who updated the form.
     * This field is used to calculate the new time for the datetime field.
     */
    let data = [<input id="timezone-last-update-user" type="hidden" value={timezone} />];

    t.split('<div').map((i, key) => {
      const required = (i || '').indexOf('formbuilder-required') > -1;
      const labelMatch = (i || '').match(/label=\"([^\"]+)\"/);
      const label = labelMatch ? labelMatch[1] : undefined;
      const addEmptyOption = i.indexOf('addemptyoption') > -1 || i.indexOf('add-empty-option') > -1;

      if (addEmptyOption && i.indexOf('<select') > -1) {
        i.replace(/>Selecione</, function (match) {
          let originalText = match.slice(1, -1);
          let newText = translateFromPtBR(originalText, language);
          return `>${newText}<`;
        });
      }

      if (i.indexOf('companies_var') > -1 || i.indexOf('fieldType="companies"') > -1) {
        let textInit = '';
        try {
          textInit =
            values && values.html_values
              ? JSON.parse(values.html_values).find(
                (v) => v.name == 'companies_var'
              ).value
              : '';
        } catch (e) { }
        data.push(
          <div separator="true" id="companies_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ companies_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'companies_var',
                  label: label || language.COMPANY,
                  method: 'POST',
                  showEmptyFilter: addEmptyOption,
                  route: `${APIEndpoints.COMPANY}/filter`,
                  fieldquery: 'search',
                  textlabel: label || language.COMPANY,
                  textinit: textInit.length > 0 ? textInit : '',
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: companyName,
                  routeAll: `${APIEndpoints.COMPANY}/all?${params[0].list_only_customer_type_companies ? 'type=1&' : ''}`,
                  methodGetAll: 'POST',
                  routeGetCount: `${APIEndpoints.COMPANY}/count`,
                  detail: true,
                  visible: true,
                  required,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_company',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('locations_var') > -1) {
        let textInit = '';
        try {
          textInit = formValues.find((v) => v.name.includes('locations_var')).value;
        } catch (e) { }

        data.push(
          <div separator="true" id="locations_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ locations_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'locations_var',
                  label: label || language.LOCATION,
                  method: 'POST',
                  showEmptyFilter: addEmptyOption,
                  route: `${APIEndpoints.LOCATION}/filter`,
                  fieldquery: 'search',
                  textlabel: label || language.LOCATION,
                  textinit: textInit,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  routeAll: `${APIEndpoints.LOCATION}/filter?`,
                  methodGetAll: 'POST',
                  detail: true,
                  visible: true,
                  required,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_location',
                  // value:
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('allusers_var') > -1 || i.indexOf('fieldType="allusers"') > -1) {
        let textInit = '';
        try {
          textInit =
            values && values.html_values
              ? JSON.parse(values.html_values).find(
                (v) => v.name == 'allusers'
              ).value
              : '';
        } catch (e) { }
        data.push(
          <div separator="true" id="allusers_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ allusers_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'allusers',
                  label: '',
                  method: 'POST',
                  route: `${APIEndpoints.USER}/filter`,
                  fieldquery: 'search',
                  showEmptyFilter: addEmptyOption,
                  textlabel: label || language.USER,
                  textinit: textInit.length > 0 ? textInit : '',
                  fieldlabeldb: 'name',
                  idinit: label || language.USER,
                  routeAll: `${APIEndpoints.USER}/filter/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${APIEndpoints.USER}/active/count/all`,
                  detail: true,
                  visible: true,
                  required,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_user',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('hours_var') > -1) {
        let nameField = 'hours_var';
        try {
          const regex = /<p(.*?)<\/p>/;
          const textWithoutTags = i.match(regex);
          nameField = textWithoutTags[1].split('|')[1];
        } catch (e) { }
        data.push(
          <div separator="true" id="hours_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ hours: value });
              }}
              fields={[
                {
                  col: 12,
                  name: nameField,
                  label: language.HOURS,
                  type: 'time',
                  format: '##:##',
                  required
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('hidden_var') > -1) {
        let value_field = '';
        let name_field = '';
        try {
          const regex = /<p(.*?)<\/p>/;
          const textWithoutTags = i.match(regex);
          name_field = textWithoutTags[1].split('|')[1];
          value_field = textWithoutTags[1].split('|')[2];
        } catch (e) { }
        const html_field = `<div hidden><input value = ${value_field} name = ${name_field} /></div>`;
        data.push(<div separator="true" dangerouslySetInnerHTML={{ __html: html_field }} />);
      } else if (i.indexOf('departments_var') > -1 || i.indexOf('fieldType="departments"') > -1) {
        let textInit = '';
        try {
          textInit =
            values && values.html_values
              ? JSON.parse(values.html_values).find(
                (v) => v.name == 'departments'
              ).value
              : '';
        } catch (e) { }
        data.push(
          <div separator="true" id="departments_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ departments_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'departments',
                  showEmptyFilter: addEmptyOption,
                  label: label || language.DEPARTMENTS,
                  textlabel: label || language.DEPARTMENTS,
                  method: 'POST',
                  route: `${APIEndpoints.DEPARTMENT}/filter/`,
                  routeGetCount: `${APIEndpoints.DEPARTMENT}/filter?count=0`,
                  fieldquery: 'search',
                  textinit: textInit.length > 0 ? textInit : '',
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: textInit.length > 0 ? textInit : dptName,
                  routeAll: `${APIEndpoints.DEPARTMENT}/filter?`,
                  methodGetAll: 'POST',
                  required,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('subsdpt_var') > -1 || i.indexOf('fieldType="subsdpt"') > -1) {
        let textInit = '';
        try {
          textInit =
            values && values.html_values
              ? JSON.parse(values.html_values).find(
                (v) => v.name == 'subdepartments'
              ).value
              : '';
        } catch (e) { }
        data.push(
          <div separator="true" id="subsdpt_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ subdepartments_var: value });
                setSubdptName(value);
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'subdepartments',
                  label: label || language.SUBDEPARTMENT,
                  textlabel: label || language.SUBDEPARTMENT,
                  showEmptyFilter: addEmptyOption,
                  method: 'POST',
                  filterAll: { fk_id_department: 1 },
                  filter: { fk_id_department: 1 },
                  route: `${APIEndpoints.DEPARTMENT}/filter/`,
                  routeGetCount: `${APIEndpoints.DEPARTMENT}/filter?count=0`,
                  fieldquery: 'search',
                  textinit: textInit.length > 0 ? textInit : '',
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${APIEndpoints.DEPARTMENT}/filter?`,
                  methodGetAll: 'POST',
                  required,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('contracts_var') > -1 || i.indexOf('fieldType="contracts"') > -1) {
        let textInit = '';
        try {
          textInit =
            values && values.html_values
              ? JSON.parse(values.html_values).find(
                (v) => v.name == 'contracts_var'
              ).value
              : '';
        } catch (e) { }
        data.push(
          <div separator="true" id="contracts_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ contracts_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'contracts_var',
                  label: label || language.CONTRACTS,
                  method: 'POST',
                  route: `${APIEndpoints.CONTRACT}/filter`,
                  showEmptyFilter: addEmptyOption,
                  fieldquery: 'search',
                  textlabel: language.CONTRACTS,
                  textinit: textInit.length > 0 ? textInit : '',
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${APIEndpoints.CONTRACT}/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${APIEndpoints.CONTRACT}/count?`,
                  textlabel: language.CONTRACTS,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_contract',
                  required
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('chieftdpt_var') > -1 || i.indexOf('fieldType="chieftdpt"') > -1) {
        let textInit = '';
        try {
          textInit =
            values && values.html_values
              ? JSON.parse(values.html_values).find((v) => v.name == 'boss_dpt')
                .value
              : '';
        } catch (e) { }
        data.push(
          <div separator="true" id="chieftdpt_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ chieftdpt_var: value });
                setChieftDptName(value);
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'boss_dpt',
                  label: label || language.DEPARTMENT_BOSS,
                  textlabel: label || language.DEPARTMENT_BOSS,
                  showEmptyFilter: addEmptyOption,
                  method: 'POST',
                  filter: { fk_id_company: 1 },
                  route: `${APIEndpoints.USER}/filter`,
                  routeGetCount: `${APIEndpoints.USER}/active/count/all`,
                  fieldquery: 'search',
                  textinit: textInit.length > 0 ? textInit : '',
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: chieftDptName,
                  routeAll: `${APIEndpoints.USER}/filter/all?`,
                  filterAll: 0,
                  methodGetAll: 'POST',
                  detail: true,
                  visible: true,
                  required,
                  disablePortal: true,
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('chiefsubdpt_var') > -1 || i.indexOf('fieldType="chiefsubdpt"') > -1) {
        let textInit = '';
        try {
          textInit =
            values && values.html_values
              ? JSON.parse(values.html_values).find(
                (v) => v.name == 'boss_sub_dpt'
              ).value
              : '';
        } catch (e) { }
        data.push(
          <div separator="true" id="chiefsubdpt_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ chiefsubdpt_var: value });
                setChiefSubDptName(value);
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'boss_sub_dpt',
                  label: label || language.SUBDEPARTMENT_BOSS,
                  textlabel: label || language.SUBDEPARTMENT_BOSS,
                  method: 'POST',
                  filter: { fk_id_company: 1 },
                  filterAll: { chiefsub: true },
                  route: `${APIEndpoints.USER}/filter`,
                  routeGetCount: `${APIEndpoints.USER}/active/count/all`,
                  fieldquery: 'search',
                  textinit: textInit.length > 0 ? textInit : '',
                  showEmptyFilter: addEmptyOption,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: chiefSubDptName,
                  routeAll: `${APIEndpoints.USER}/filter/all?`,
                  methodGetAll: 'POST',
                  required,
                  disablePortal: true,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('chief_var') > -1 || i.indexOf('fieldType="chief"') > -1) {
        let textInit = '';
        try {
          textInit =
            values && values.html_values
              ? JSON.parse(values.html_values).find(
                (v) => v.name == 'all_boss_dpt'
              ).value
              : '';
        } catch (e) { }
        data.push(
          <div separator="true" id="chief_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ chief_var: value });
                setAllChieftName(value);
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'all_boss_dpt',
                  label: label || language.DEPARTMENT_BOSS,
                  textlabel: label || language.DEPARTMENT_BOSS,
                  showEmptyFilter: addEmptyOption,
                  method: 'POST',
                  filter: { fk_id_company: 1 },
                  filterAll: { all_chief: true },
                  route: `${APIEndpoints.USER}/filter`,
                  routeGetCount: `${APIEndpoints.USER}/active/count/all`,
                  fieldquery: 'search',
                  textinit: textInit.length > 0 ? textInit : '',
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: allChiefName,
                  routeAll: `${APIEndpoints.USER}/filter/all?`,
                  methodGetAll: 'POST',
                  required,
                  disablePortal: true,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('departmentall_var') > -1 || i.indexOf('fieldType="departmentall"') > -1) {
        let textInit = '';
        try {
          textInit =
            values && values.html_values
              ? JSON.parse(values.html_values).find(
                (v) => v.name == 'all_departments'
              ).value
              : '';
        } catch (e) { }
        data.push(
          <div separator="true" id="departmentall_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ departmentall_var: value });
                setAllDptName(value);
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'all_departments',
                  label: label || language.DEPARTMENTS,
                  textlabel: label || language.DEPARTMENTS,
                  showEmptyFilter: addEmptyOption,
                  method: 'POST',
                  route: `${APIEndpoints.DEPARTMENT}/filter/`,
                  routeGetCount: `${APIEndpoints.DEPARTMENT}/filter?count=0`,
                  fieldquery: 'search',
                  textinit: textInit.length > 0 ? textInit : '',
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: allDptName,
                  routeAll: `${APIEndpoints.DEPARTMENT}/filter?`,
                  methodGetAll: 'POST',
                  required,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('Multidepartment_var') > -1 || i.indexOf('fieldType="multidepartment"') > -1) {
        let nameField = '';
        const regex = /<p(.*?)<\/p>/;
        const textWithoutTags = i;
        nameField = textWithoutTags.split('"')[1];
        nameField = nameField.split('<')[0];

        let textInit = '';
        try {
          textInit =
            values && values.html_values
              ? JSON.parse(values.html_values).find((v) => v.name == nameField)
                .value
              : '';
        } catch (e) { }
        data.push(
          <div separator="true" id={nameField} style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ departmentall_var: value });
                let MD = multiDepartment;
                MD.push({ index: nameField, value: value });
                setMultiDepartment(MD);
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: `${nameField}`,
                  showEmptyFilter: addEmptyOption,
                  label: '',
                  textlabel: '',
                  method: 'POST',
                  route: `${APIEndpoints.DEPARTMENT}/filter/`,
                  routeGetCount: `${APIEndpoints.DEPARTMENT}/filter?count=0`,
                  fieldquery: 'search',
                  textinit: textInit,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: textInit,
                  routeAll: `${APIEndpoints.DEPARTMENT}/filter?`,
                  methodGetAll: 'POST',
                  required,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>
        );
      } else {
        if (i.length > 1) {
          //é preciso eliminar quaisquer trechos que não sejam úteis, caso contrario, esse template ficará maior a cada vez que for editado na tela, podendo travar o navegador do usuário
          let g = '';
          if (i.indexOf('ql-tooltip ql-hidden') > -1) {
            g = `<div style = "display : none" ${i}`;
          } else if (
            i.indexOf('ql-preview') > -1 ||
            i.indexOf('ql-clipboard') > -1 ||
            i.indexOf('ql-snow') > -1
          ) {
            g = `<div  ${i}`;
          } else if (i.indexOf('ql-editor') > -1) {
            g = `<div style = "border: 1px solid #629daa80; min-height : 30px; border-radius : 0px 0px 10px 10px" ${i}`;
          } else if (
            i.indexOf('id="boss_dpt"') > -1 ||
            i.indexOf('id="boss_dpt-label"') > -1 ||
            i.indexOf('id="boss_sub_dpt"') > -1 ||
            i.indexOf('id="boss_sub_dpt-label"') > -1 ||
            i.indexOf('id="all_boss_dpt"') > -1 ||
            i.indexOf('id="all_boss_dpt-label"') > -1 ||
            i.indexOf('id="subdepartments"') > -1 ||
            i.indexOf('id="subdepartments-label"') > -1 ||
            i.indexOf('id="departments"') > -1 ||
            i.indexOf('id="departments-label"') > -1 ||
            i.indexOf('id="all_departments-label"') > -1 ||
            i.indexOf('id="all_departments"') > -1 ||
            i.indexOf('id="allusers_var"') > -1 ||
            i.indexOf('id="boss"') > -1 ||
            i.indexOf(' *</span>') > -1 ||
            i.indexOf('class="CommonForm2"') > -1 ||
            i.indexOf('Autocomplete') > -1 ||
            i.indexOf('hours_var') > -1 ||
            i.indexOf('Multidepartment_var') > -1 ||
            // i.indexOf('>Usuário<') > -1 ||
            //i.indexOf('>Horas</label>') > -1 ||
            i.indexOf('type="button"') > -1
          ) {
          } else {
            g = `<div class = "template-dinamic" ${i}`;
          }
          const div_with_no_children = /<div [^>]+>(.*?)<\/div>/g.exec(g);
          if (
            div_with_no_children &&
            div_with_no_children.length > 1 &&
            div_with_no_children[1] == ''
          ) {
            g = '';
          }

          g = g.replace(
            /<div class = "template-dinamic"  class="template-dinamic"><\/div><\/div>/g,
            ''
          );
          g = g.replace(/<div class = "template-dinamic" >/g, '');
          g = g.replace(/<div><\/div>/g, '');
          g = g.replace(/<div><\/div>/g, '');
          g = g.replace(
            /<div><div class="template-dinamic"><\/div><\/div>/g,
            ''
          );
          g = `<div separator="true">${g}</div>`
          if (g.length > 0) {
            data.push(<div dangerouslySetInnerHTML={{ __html: g }} />);
          }
        }
      }
    });



    return data;
  };

  const buildForm = (formFields, formValues) => {

  }

  const createRandomToken = (text) => {
    const aSecret = 'Orch3str0!@forever!';

    try {
      const crypto = require('crypto');

      let cipher, tRet;

      cipher = crypto.createCipher('aes-256-cbc', aSecret);
      tRet = cipher.update(text.toString(), 'utf8', 'base64');
      tRet += cipher.final('base64');
      return tRet;
    } catch (e) {
      return {};
    }
  };

  const devices = (user) => {
    action
      .execute('get', APIEndpoints.DEVICE + 'user/' + user, 'devices')
      .then((h) => {
        setForm_({ devicesUser: h.data[0] });
      });
    setForm_({ user: user });
  };

  const deviceContractSolicitant = (id_contract, id_user) => {
    if ((id_contract || form_.contract) > 0 && (id_user || form.fk_id_user) > 0)
      action
        .execute(
          'get',
          APIEndpoints.DEVICE +
          '/contract/' +
          (id_contract || form_.contract) +
          '/' +
          (id_user || form.fk_id_user)
        )
        .then((h) => {
          setDeviceOptions(
            h.data.map((x) => ({ value: x.id_device, label: x.name }))
          );
        });

    // let userSelect = users.filter(c => c.id_user === id_user)[0];
    // setuserDetail(userSelect)
  };
  const catalogGroup = (id) => {
    //findAllcat
    action
      .execute('get', APIEndpoints.CATALOG + '/group/' + id, '')
      .then((b) => {
        setgroupcat(b.data[0]);
      });
  };

  const userDetailFunc = (id_user) => {
    action
      .execute('get', APIEndpoints.TICKET + '/userdetail/' + id_user, '')
      .then((b) => {
        setuserDetail(b.data);
      });
  };

  const catalogCompany = (company, v) => {
    if (company && v) {
      action
        .execute(
          'get',
          `${APIEndpoints.CATALOG}/company/${company}/${v}?requesterId=${requesterId}&origin=ticket`,
          ''
        )
        .then((b) => {
          setCatalogs(b.data);
        });
    } else if (v) {
      action
        .execute(
          'get',
          `${APIEndpoints.CATALOG}/company/0/${v}?requesterId=${requesterId}&origin=ticket`,
          '')
        .then((b) => {
          setCatalogs(b.data);
        });
    }
  };

  const categoryCompany = (company) => {
    if (company) {
      action
        .execute('get', APIEndpoints.CATEGORY + '/company/' + company, '')
        .then((b) => {
          setCategories(b.data);
        });
    } else {
      // action.execute('get', APIEndpoints.CATEGORY, '').then((b) => {
      //  setCategories(b.data);
      //  });
    }
  };

  const categoryByCompanyRequester = () => {
    action
      .execute('get', `${APIEndpoints.CATEGORY}/company/${companyId}?requesterId=${requesterId}&origin=ticket`, '')
      .then((b) => {
        setCategories(b.data);
      });
  };

  const chiefDepartment = (id_user) => {
    try {
      if (userSelect) {
        if (form_.company > 0 || form_.fk_id_company > 0) {
          action
            .execute(
              'get',
              APIEndpoints.DEPARTMENT +
              '/chief/' +
              id_user +
              '/' +
              (form_.company == '' ? form_.fk_id_company : form_.company)
            )
            .then((h) => {
              setresponsibleOptions(
                h.data.map((x) => ({ value: x.id_user, label: x.name }))
              );
              setdepartmentOptions(
                h.data.map((x) => ({
                  value: x.id_department,
                  label: x.namedepartment,
                }))
              );
              if (h.data && h.data.length > 0) {
                setForm({ fk_id_department: h.data[0].id_department });
                setForm({ fk_id_user_sponsor: h.data[0].id_user });
                setForm({ namesponsor: h.data[0].name });
              }
            });
        }
      }
    } catch (e) { }
  };
  const usersCompany = (id_company) => {
    action
      .execute('get', APIEndpoints.USER + 'company/' + id_company)
      .then((h) => {
        SetsolicitantOptions(
          h.data
            .filter((b) => b.name != '')
            .map((x) => ({
              value: x.id_user,
              label: x.name,
              fk_id_department: x.fk_id_department,
              role: x.role,
            }))
        );
      });
  };

  const findLocation = (id_user) => {
    if (id_user) {
      action
        .execute('post', `${APIEndpoints.USER}/filter/all?`, '', { fk_id_user: id_user })
        .then((user) => {

          if (user.data.length > 0) {
            if (user.data[0].fk_id_rel_company_location) {
              action
                .execute(
                  'get',
                  APIEndpoints.LOCATION + `/company/${companyId}`
                )
                .then((locations) => {
                  if (locations.data.length > 0) {
                    let location = locations.data.filter(location => location.id_rel_company_location === user.data[0].fk_id_rel_company_location);
                    setForm({ fk_id_location: location[0].id_location });
                  }
                });
            }
          }
        });
    }
  }

  /**api/department/chief/:id/:company */


  const onFormSubmitTab_ = () => {
    setIsProcessingSave(true);
    let fields = [];
    SetDs(false);
    setTimeout(() => {
      SetDs(true);
    }, 4000);
    if (values && chieftDptName !== 'Chefe de Departamento') {
      values.approver_name = chieftDptName;
    }
    let contador = 0;
    let campossize = false;
    let camposobrigatorios = false;
    let camposobrigatoriosradio = false;
    let camposobrigatorioscheck = false;

    if ($('#template').html() != undefined) {
      $('#template textarea').each(function () {
        const textarea = $(this);
        textarea.attr('value', textarea.val());
        textarea.html(textarea.val());

        fields.push({
          name: textarea.attr('name'),
          value: textarea.val(),
        });

        const label = $(this).parent().find('label');
        if (label.text().split('*')[1] != undefined) {
          if (label.text().split('*')[1].length >= 0) {
            if (!textarea.val()) {
              camposobrigatorios = true;
              textarea.css('border-color', 'red');
            } else {
              textarea.css('border-color', 'var(--primary)');
            }
          }
        }
      });

      $('#template input').each(function () {
        if ($(this).attr('type') == 'radio') {
          // $(this).removeAttr('checked');

          if ($(this).is(':checked')) {
            $(this).attr('checked', true);
            fields.push({
              name: $(this).attr('name'),
              value: $(this).attr('value'),
            });
          }

          let ip = $(this);

          $('#template  label').each(function () {
            var for_ = '';
            try {
              for_ = $(this).attr('for');
            } catch (e) { }

            if (for_ === ip.attr('name')) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  camposobrigatoriosradio = true;
                  $('[name=' + for_ + ']').each(function () {
                    try {
                      if ($(this).is(":checked")) {
                        camposobrigatoriosradio = false;
                      }
                    } catch (e) { }
                  });
                  $(this).css('color', camposobrigatoriosradio ? 'red' : 'var(--primary)');
                }
              }
            }
          });
        } else if ($(this).attr('type') == 'checkbox') {
          if (
            $('input[id=' + $(this).attr('id') + ']:checked').attr('id') ==
            $(this).attr('id')
          ) {
            $(this).attr('checked', true);
            fields.push({
              name: $(this).attr('name'),
              value: $(this).attr('value'),
            });
          } else {
            $(this).removeAttr('checked');
          }
          let ip = $(this);
          $('#template  label').each(function () {
            var for_ = ' ';
            try {
              for_ = $(this).attr('for');
            } catch (e) { }

            if (ip.attr('name').indexOf(for_) > -1) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  camposobrigatorioscheck = true;
                  $('#template  input').each(function () {
                    try {
                      if ($(this).attr('name').indexOf(for_) > -1) {
                        try {
                          if ($(this).attr('checked')) {
                            camposobrigatorioscheck = false;
                          }
                        } catch (e) { }
                      }
                    } catch (e) { }
                  });
                  $(this).css('color', camposobrigatorioscheck ? 'red' : 'var(--primary)');
                }
              }
            }
          });
        } else if ($(this).attr('type') == 'datetime-local') {
          const userLogin = Store.getUserLoged();
          const timezone = userLogin?.timezone?.value;

          fields.push({
            name: $(this).attr('name') || $(this).attr('id'),
            value: moment($(this).val()).tz(timezone).utc().toISOString(),
          });
          $(this).attr('value', $(this).val());

          let ip = $(this);
          $('#template  label').each(function () {
            if ($(this).attr('for') === ip.attr('id')) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  if (ip.attr('value').length == 0) {
                    ip.css('border-color', 'red');
                    camposobrigatorios = true;
                  } else {
                    ip.css('border-color', 'var(--primary)');
                  }
                }
              }
            }
          });
        } else {
          $(this).attr('value', $(this).val());
          fields.push({
            name: $(this).attr('name') || $(this).attr('id'),
            value: $(this).attr('value'),
          });
          let siz = $(this).attr('max');
          let min = $(this).attr('min');
          if (siz) {
            if (
              parseInt($(this).val().length) > parseInt(siz) ||
              parseInt($(this).val().length) < parseInt(min)
            ) {
              $(this).css('border-color', 'red');
              $(this).before(
                `<label  id="formtemplabel__" style='color:red'>  ${min || 0
                } á ${siz || 0} caracteres</label>`
              );

              campossize = true;
            }
          }

          let ip = $(this);
          $('#template  label').each(function () {
            if ($(this).attr('for') === ip.attr('id')) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  if (ip.attr('value').length == 0 || ip.attr('value') === `(${language.EMPTY})`) {
                    ip.css('border-color', 'red');
                    ip.parent().find('fieldset').css('border-color', 'red');
                    camposobrigatorios = true;
                  } else {
                    ip.css('border-color', 'var(--primary)');
                    ip.parent().find('fieldset').css('border-color', 'var(--primary)');

                  }
                }
              }
            }
          });
        }
      });

      $('#template select').each(function () {
        const isMultiple = $(this).attr('multiple') !== undefined;
        const selectedValues = isMultiple ? $(this).val() : $(this).val() || "";

        const label = $(this).parent().find('label');
        if (label.text().split('*')[1] != undefined) {
          if (label.text().split('*')[1].length >= 0) {
            if (isMultiple ? selectedValues.length === 0 : !selectedValues) {
              camposobrigatorios = true;
              $(this).css('border-color', 'red');
            } else {
              $(this).css('border-color', 'var(--primary)');
            }
          }
        }

        fields.push({
          name: $(this).attr('name') || $(this).attr('id'),
          value: selectedValues
        });
      });

      $('#template label').each(function () {
        try {
          if ($(this).attr('for').indexOf('textarea') > -1) {
            if ($(this).text().split('*')[1] != undefined) {
              if ($(this).text().split('*')[1].length >= 0) {
                // alert($('#'+$(this).attr('for')).text())

                let node_ = $(this)
                  .parent()
                  .parent()
                  .nextAll()
                  .slice(2, 3)
                  .children()[0];
                if (
                  (node_ &&
                    node_.firstChild.tagName == 'P' &&
                    node_.firstChild.textContent.length == 0) ||
                  (!node_ && $('#' + $(this).attr('for')).text().length == 0)
                ) {
                  camposobrigatorios = true;
                }
              }
            }
          }
        } catch (e) { }
      });
      if (templatesForm.length > 0) {
        form.template = templatesForm[0].html;
      }

    }

    setTimeout(function () {
      $('#formtemplabel__').fadeOut().empty();
      $('#template .form-control').css('border-color', '#019fc2');
    }, 9000);

    form.json_template_solicitation = json_template_solicitation;
    if (
      camposobrigatorios == false &&
      campossize == false &&
      camposobrigatorioscheck == false &&
      camposobrigatoriosradio == false
    ) {
      setTimeout(
        function () {
          try {
            form1.submit();
            form2.submit();
            form3.submit();
            form4.submit();
          } catch (err) { }

          try {
            if (values.ticketArray.length > 1) {
              form.fk_id_device = 0;
              form.ticketArray = values.ticketArray;
              form.type = 'ticket';
            }
          } catch (e) {
            form.ticketArray = [];
          }
          try {
            if (values.arraytk.length > 1) {
              form.fk_id_device = 0;
              form.ticketArray = values.arraytk;
              form.type = 'ticket';
            }
          } catch (e) {
            form.ticketArray = [];
          }
          if (
            values.has_multi_ticket !== true &&
            (form.type_tickets == null ||
              form.fk_id_company == null ||
              // form.fk_id_device == null ||
              form.fk_id_contract == null ||
              form.fk_id_user == null ||
              ((values.ticketArray.length == 0 ||
                (values.ticketArray.length > 0 &&
                  keepOriginalCatalog.keep == false)) &&
                (form.fk_id_group_tech == null ||
                  form.fk_id_group_tech == 0 ||
                  form.fk_id_category == null ||
                  form.fk_id_catalog_service == null ||
                  form.fk_id_catalog_task == null ||
                  (attachIsRequired && form.files.length === 0))) ||
              form.subject == null ||
              form.subject == '' ||
              form.recurrent == null ||
              form.description == null ||
              form.description == '' ||
              form.description.length == 0 ||
              (generalParams.enable_requirement_channel_ticket && form.fk_id_channel < 1))
          ) {
            setAttachmentError(attachIsRequired && form.files.length === 0);
            store.toggleAlert(true, language.FILL_IN_ALL_FIELDS, 'error');
            setIsProcessingSave(false);
          } else {
            form.fields = fields;
            form.keepOriginalCatalog = keepOriginalCatalog.keep;
            form.has_multi_ticket = values.has_multi_ticket;

            onFormSubmitTab({
              ...form,
              time_recurrence: form?.time_recurrence ? moment(form?.time_recurrence).format('YYYY-MM-DD HH:mm:ss') : '',
              dt_start_crisis: form.dt_start_crysis ? concatDateHour(form.dt_start_crysis, form.dt_starttime_crysis) : null
            }, () => {
              setIsProcessingSave(false);
            });
          }
        }.bind(this),
        2000
      );
    } else {
      if (
        camposobrigatorios ||
        camposobrigatoriosradio ||
        camposobrigatorioscheck
      ) {
        store.toggleAlert(true, language.INFORM_REQUIRED_FIELDS, 'error');
        setIsProcessingSave(false);
      }

      if (campossize) {
        store.toggleAlert(true, language.CHECK_FIELD_SIZES, 'error');
        setIsProcessingSave(false);
      }
    }
  };
  const GC = ({ items, labelProps = {}, valueProps = {} }) => (
    <div className="grid-container">
      {items.map((e, i) => (
        <Fragment>
          <div
            {...labelProps}
            {...e.labelProps}
            className={classnames(
              'grid-columns label',
              labelProps.className,
              (e.labelProps || {}).className
            )}
          >
            {e.label}
          </div>
          <div
            {...valueProps}
            {...e.valueProps}
            className={classnames(
              'grid-columns value',
              valueProps.className,
              (e.valueProps || {}).className
            )}
          >
            {e.value}
          </div>
        </Fragment>
      ))}
    </div>
  );

  useEffect(() => {
    if (!generalParams || Object.keys(generalParams).length === 0) {
      // Se ainda não carregou os generalParams, faça a requisição
      action.execute('get', APIEndpoints.GENERAL_PARAMETERS, '').then((res) => {
        setGeneralParams(res.data);
        if (!res.data.enable_requirement_channel_ticket) {
          setForm({ fk_id_channel: res.data.fk_id_channel_default });
        }
      });
    }
  }, [generalParams]); // Dependência de generalParams, vai rodar quando mudar

  useEffect(() => {
    // Este useEffect é responsável por carregar os companies, só após os generalParams
    if (generalParams && Object.keys(generalParams).length > 0 && companies.length === 0) {
      try {
        action.execute(
          'get',
          `${APIEndpoints.COMPANY}/all?${generalParams.list_only_customer_type_companies ? 'type=1&?offset=0&limit=25' : ''}`,
          'companies'
        ).then((item) => {
          if (item.data.length > 0)
            setCompanies(item.data);
        });
      } catch (e) {
        console.error(`Error: An unexpected error occurred when searching for a list of companies 
                       File: src/components/TicketManagement/DialogForm.js 
                       Line: 1934`)
      }
    }
  }, [generalParams, companies]); // Dependência em generalParams e companies

  useEffect(() => {
    const getChannels = async () => {
      const result = await getChannelsService();
      setChannel(result);
    };

    getChannels();

    if (
      values.fk_id_user == store.getUserLoged().id &&
      values.fk_id_catalog_service > 0 &&
      store.getUserLoged().role != 'client'
    ) {
      action.execute('get', APIEndpoints.TICKET + '/catalog').then((v) => {
        let cat_service = v.data.map((g) => g.id_catalog_service);
        if (cat_service.includes(values.fk_id_catalog_service)) {
        } else {
          store.toggleAlert(
            true,
            language.THE_TECHNICIAN_NOT_ALLOWED_CATEGORIZE_CALLS_GROUP_THAT_DOES_NOT_BELONG,
            'error'
          );
          setTimeout(() => {
            onClose();
          }, 4000);
        }
      });
    }

    if (contract.length == 0) {
      action
        .execute(
          'get',
          APIEndpoints.CONTRACT + 'companylow/' + values.fk_id_company
        )
        .then((i) => {
          setContracts(i.data);
        });
    }

    action.execute('get', APIEndpoints.GENERAL_PARAMETERS, '').then((res) => {
      setGeneralParams(res.data);
      if (!res.data.enable_requirement_channel_ticket)
        setForm({ fk_id_channel: res.data.fk_id_channel_default });
    });

    action.execute('get', APIEndpoints.GROUP, 'groups');
    action.execute('get', APIEndpoints.TABLE_STRUCTURED + '/40', 'urgencies');
    action.execute('get', APIEndpoints.TABLE_STRUCTURED + '/60', 'priorities');
    action.execute(
      'get',
      APIEndpoints.TABLE_STRUCTURED + '/70',
      'type_tickets'
    );

    action.execute('get', APIEndpoints.TABLE_STRUCTURED + '/50', 'impacts');
    action.execute('get', APIEndpoints.SLA, 'slas');
    action.execute('get', APIEndpoints.USER + '/view', 'view');
    if (values.type_ticket_cat > 0) {
      setForm({ type_tickets: values.type_ticket_cat });
    } else {
      setForm({ type_tickets: 4 });
    }
    setForm({ req: values.req });
    setForm_({ recurrent: 1 });
    devices(values.fk_id_user);
    setForm({ fk_id_device: values.fk_id_device });
    setForm({ fk_id_contract: values.fk_id_contract });
    setForm({ fk_id_group_tech: values.fk_id_group_tech });
    setForm({ fk_id_company: values.fk_id_company });
    setForm_({ fk_id_company: values.fk_id_company });

    setForm({ fk_id_user: values.fk_id_user });
    setForm({ subject: values.subject });
    setForm({ description: values.description });
    setForm({ fk_id_category: values.fk_id_category });
    setForm({ fk_id_catalog_service: values.fk_id_catalog_service });
    setForm({ fk_id_catalog_task: values.fk_id_catalog_task });
    chiefDepartment(values.fk_id_user);
    if (values.fk_id_company > 0 && values.fk_id_category > 0) {
      // catalogCompany(values.fk_id_company, values.fk_id_category)
    }
    if (values.fk_id_catalog_service > 0) {
      catalogGroup(values.fk_id_catalog_service);
    }

    if (values.fk_id_catalog_task > 0) {
      getFormCatalog(values.fk_id_catalog_task);
    }

    if (values.fk_id_company > 0) {
      usersCompany(values.fk_id_company);
    }

    var items =
      values.files != undefined
        ? values.files.map((f) => ({
          name: f.split('|')[0] + ' - ' + moment(f.split('|')[3]).format('L'),
          size: '',
          type: '',
          nn: f.split('|')[0],
          id_tickets_file: f.split('|')[2],
          id_request_file: f.split('|')[1],
          fk_id_request: values.fk_id_request,
          user_cad: '',
          id_user: '',
        }))
        : null;
    if (items) {
      setForm({ filesrequest: items });
    }

    try {
      if (params[0].ticket_contract_solicitant == true) {
        setSolicitantGlobal(true);
      }
    } catch (e) { }

    if (values.fk_id_request && values.fk_id_request > 0 && values.fk_id_user && values.fk_id_user > 0) {
      setCompanyId(values.fk_id_request);
      setRequesterId(values.fk_id_user);
    }

    if (values.fk_id_request > 0) {
      // categoryCompany(values.fk_id_company);
      setTimeout(() => {
        if (values.fk_id_category > 0) {
          catalogCompany(values.fk_id_company, values.fk_id_category);
        }
      }, 2000);

      if (
        params[0].avaliation_tickets == true &&
        store.getUserLoged().role != 'tech'
      ) {
        action
          .execute(
            'post',
            APIEndpoints.TICKET + '/ticketFind',
            '',
            { avaliationticketunique: true },
            {},
            null,
            null
          )
          .then((g) => {
            if (g.data.length > 0) {
              setTicketavaliation(true);
            }
          });
      }
    }

    try {
      chiefDepartment(values.fk_id_user);
    } catch (e) { }

    if (category) {
      action
        .execute(
          'get',
          APIEndpoints.TASK + '/findCategoryType/' + category,
          '',
          {},
          null,
          null
        )
        .then((g) => {
          setForm({ type_tickets: g.data.type_ticket });
        });
    }
  }, []);

  useEffect(() => {
    if (values.fk_id_catalog_service > 0) {
      let t = catalogs
        .filter((c) => c.id_catalog_service === values.fk_id_catalog_service)
        .map((t) => t.id_group_users);

      let itens = groups
        .filter((g) => g.id_group_users == t[0])
        .map((tec) => ({ value: tec.id_group_users, label: tec.name }));

      let itens_ = groups
        .filter((g) => g.type == 1 && g.type_tech == 1)
        .map((tec) => ({ value: tec.id_group_users, label: tec.name }));

      settechGroupOptions(itens);

      settechGroupOptions(itens_);
      try {
        setForm({ fk_id_group_tech: itens[0].value });
        setForm_({ id_grouptech: itens[0].value });
        setgroupcat({ name: itens[0].label });
      } catch (e) {
        //setForm({ fk_id_group_tech: 0 });
        //setForm_({ id_grouptech: 0 });
        //setgroupcat({name : ''})
      }
    } else {
      let itens_ = groups
        .filter((g) => g.type == 1 && g.type_tech == 1)
        .map((tec) => ({ value: tec.id_group_users, label: tec.name }));
      settechGroupOptions(itens_);
    }
  }, [catalogs]);

  const getFormCatalog = (id) => {
    action
      .execute('get', APIEndpoints.TASK + '/getformticket/' + id, '')
      .then((g) => {
        if (
          values &&
          Object.keys(values).length > 0 &&
          //!values.fk_id_catalog_task &&
          //values.fk_id_catalog_task != g.data[0].fk_id_catalog_task &&
          g.data &&
          g.data.length > 0
        ) {
          setTemplateFormOriginal(g.data[0].html);
          values.fk_id_catalog_task = g.data[0].fk_id_catalog_task;

          setTemplateForms(g.data);
          // setHtmlOriginal(decryp(d.html));
          let c = 0;
          let item = g.data.map((obj) => {
            c = c + 1;
            let id = 'ID' + c;

            return (
              <div className="contain">
                <div className="itemTicketDetail">
                  <p style={{ color: '#888080' }} id={id}>
                    {formshtml(decryp(obj.html))}
                  </p>
                </div>
                <div style={{ marginTop: '30px' }} />
                <div>
                  {obj.user_up != null
                    ? language.LAST_UPDATE +
                    obj.user_up +
                    '-' +
                    moment(obj.dt_up).format('DD-MM-YY HH:mm:ss')
                    : null}
                </div>
                <hr />
              </div>
            );
          });
          try {
            setForms(item[0]);
          } catch (e) {
            console.log(e);
          }
        }
        else {
          setTemplateFormOriginal('')
        }
        setFormsTemplate([]);
        setForms([]);
      });
  };

  const companyallContract = (id) => {
    action
      .execute('get', APIEndpoints.CONTRACT + 'companylow/' + id)
      .then((i) => {
        setContracts(i.data);
      });
  };

  let company = form_.company || values.fk_id_company;

  form.recurrent = values.recurrent ? values.recurrent : form.recurrent;
  form.fk_id_request = values.fk_id_request ? values.fk_id_request : null;
  let contract = values.fk_id_contract ? values.fk_id_contract : form_.contract;

  let user = form_.user == '' ? values.fk_id_user : form_.user;

  let category =
    form_.category == '' ? values && values.fk_id_category : form_.category;

  let catalog =
    form_.catalog == ''
      ? values && values.fk_id_catalog_service
      : form_.catalog;

  let groutechfk = values.fk_id_group_tech
    ? values.fk_id_group_tech
    : form_.id_grouptech;

  let catalog_task =
    form_.catalog_task == '' ? values.fk_id_catalog_task : form_.catalog_task;

  let userSelect = []; // users.filter(c => c.id_user === user)[0];

  let typeOptions; /*= type_tickets.map(tk => ({
      value: tk.item_table,
      label: tk.description
    })); */
  let br = sessionStorage.getItem('sensr_lang') == 'PT_BR';

  let contractOptions = [];

  let companyOptions = companies.map((c) => ({
    value: c.id_company,
    label: c.name,
  }));

  if (store.getUserLoged().role == 'solicitant') {
    companyOptions = [];

    contracts = contracts.filter((b) =>
      store.getUserLoged().contract.includes(b.id_contract)
    );
    let comp = [];

    contracts.map((b) => {
      comp.push(b.fk_id_out_company);
    });

    companyOptions = companies
      .filter((c) => comp.includes(c.id_company))
      .map((c) => ({ value: c.id_company, label: c.name }));
  }

  //retirei aqui
  if (form.fk_id_company == 1) {
    contractOptions = contracts
      .filter((c) => c.fk_id_company === company || c.global == true)
      .map((ctr) => ({ value: ctr.id_contract, label: ctr.name }));
  } else {
    contractOptions = contracts
      .filter((c) => c.fk_id_out_company === company || c.global == true)
      .map((ctr) => ({ value: ctr.id_contract, label: ctr.name }));
  }
  let template = [];
  if (form.fk_id_request == null) {
    if (catalog_task) {
      template = formstemplate.filter(
        (c) => c.fk_id_catalog_task === parseInt(catalog_task)
      );

      if (template.length >= 1 && template[0].html) {
        let d = formshtml(decryp(template[0].html));
        template = [{ html: d }];
      }
    }
  }

  if (values) {
    if (templateFormOriginal && templateFormOriginal !== null) {
      template = generalParams.allow_edit_form_categorization
        ? [{ html: formshtmlWithEdit(decryp(templateFormOriginal)) }]
        : [{ html: applyTimezoneOnHTML(decryp(templateFormOriginal)) }];
    }
  }

  let techgroups = [];
  groupstech
    .filter((g) => g.fk_id_group_users == groutechfk)
    .map((tec) => techgroups.push({ value: tec.id_user, label: tec.name }));

  groupstech = groupstech.sort(function (a, b) {
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    return 0;
  });

  let techgroups_aux = [];
  groutechfk == 0
    ? groupstech
      .filter((g) => g.fk_id_group_users == form.fk_id_group_tech)
      .map((tec) => {
        solicitantOptions
          .filter((user) => user.value == tec.id_user && user.role === 'tech')
          .map((result) => {
            techgroups_aux.push({
              fk_id_group_users: form.fk_id_group_tech,
              id_user: result.id_user,
              name: (result.vip ? 'VIP - ' : '') + result.name,
            });
          });
      })
    : groupstech
      .filter((g) => g.fk_id_group_users == groutechfk)
      .map((tec) => {
        solicitantOptions
          .filter((user) => user.value == tec.id_user && user.role === 'tech')
          .map((result) => {
            techgroups_aux.push({
              fk_id_group_users: groutechfk,
              id_user: result.id_user,
              name: (result.vip ? 'VIP - ' : '') + result.name,
            });
          });
      });

  groupstech = techgroups_aux;

  // this.state.categoryOptions = categories.map(c => ({ value: c.id_category, label: c.name }));

  let tasksOptions = [];

  let t = [];

  let serviceCatalogOptions = [];

  let responsible = null;
  let priorityOptions = [];
  let urgencyOptions = [];

  let slaOptions = [];

  let impactsOptions = [];
  let idsla = [];
  let idpriority = [];
  let idurgency = [];
  let idimpact = [];
  let idcomplexity = [];

  /* if (contract && contracts != []) {
        deviceOptions = contracts
          .filter(c => c.id_contract === contract)[0]
          .device.map(d => ({ value: d.id_device, label: d.name }));
      } */

  if (values && values.fk_id_user && form_.devicesUser) {
    if (deviceOptions.length == 0) {
    }
  } else {
  }

  if (form_.device != '') {
    if (form_.fk_id_company == 1) {
      contracts
        .filter((c) => c.fk_id_company === company)
        .map((x) => {
          if (
            contractOptions.filter((g) => g.value == x.id_contract).length == 0
          )
            contractOptions.push({ value: x.id_contract, label: x.name });
        });
    } else {
      contracts
        .filter((c) => c.fk_id_out_company === company)
        .map((x) => {
          if (
            contractOptions.filter((g) => g.value == x.id_contract).length == 0
          )
            contractOptions.push({ value: x.id_contract, label: x.name });
        });
    }
  }

  try {
    if (category) {
      serviceCatalogOptions = catalogs.map((cat) => ({
        value: cat.id_catalog_service,
        label: cat.name,
      }));

      if (catalog) {

        if (catalogs.length > 0) {
          try {
            catalogs
              .filter((g) => g.id_catalog_service == catalog)[0]
              .task.map((t) => {
                tasksOptions.push({
                  value: t.id_catalog_task,
                  label:
                    t.name +
                    ' - ' +
                    t.description.replace(/<\/?[^>]+(>|$)/g, ''),
                });
              });
          } catch (e) { }
        }

        if (catalog_task === null || catalog_task == undefined) {
        } else {

          // const selectedTask = catalogs
          //   .filter((item) => item.id_catalog_service === catalog)[0]
          //   .task.filter((item) => item.id_catalog_task === catalog_task)[0];
          // const description = selectedTask?.script;

          // if(form.description !== description)
          //   setForm({description: description});

          let taskcatalog = catalogs
            .filter((g) => g.id_catalog_service == catalog)[0]
            .task.filter((c) => c.id_catalog_task == catalog_task)[0];

          idurgency = [taskcatalog.id_urgency];

          idimpact = [taskcatalog.id_impact];

          idpriority = [taskcatalog.id_priority];

          idcomplexity = [taskcatalog.id_complexity];

          priorityOptions = priorities
            .filter((c) => c.id_table === idpriority[0])
            .map((pri) => ({ value: pri.id_table, label: pri.description }));

          urgencyOptions = urgencies
            .filter((c) => c.id_table === idurgency[0])
            .map((ur) => ({ value: ur.id_table, label: ur.description }));

          idsla =
            taskcatalog[0] != undefined &&
            taskcatalog.map((t) => t.time_sla)[0];

          // slaOptions = slas.filter(c => c.id_sla === idsla).map(sl => ({ value: sl.id_sla, label: sl.name }));
          slaOptions = slas.map((sl) => ({ value: sl.id_sla, label: sl.name }));

          impactsOptions = impacts
            .filter((c) => c.id_table === idimpact[0])
            .map((i) => ({ value: i.id_table, label: i.description }));
        }
      }
    }
  } catch (e) { }
  // if feito para que os dados iniciais sejam carregados sem que seja gerado um bug ao setar um grupo tecnico
  setTimeout(function () {
    values.OpenCategorize = false;
  }, 9000);
  if (idpriority) {
    form.fk_id_priority = idpriority[0];
  }
  if (idurgency && !form.fk_id_urgency && !form.fk_id_urgency > 0) {
    form.fk_id_urgency = idurgency[0];
  }

  if (idimpact) {
    form.fk_id_impact = idimpact[0];
  }

  if (idcomplexity) {
    form.fk_id_complexity = idcomplexity[0];
  }

  if (catalog) {
    // form.fk_id_group_tech = form_.id_grouptech > 0 ? form_.id_grouptech : t[0];
    //groutechfk = form.fk_id_group_tech;
  }

  if (idsla) {
    form.fk_id_sla = idsla;
  }

  try {
    if (values.arraytk.length > 1) {
      values.ticketArray = values.arraytk;
    }
  } catch (e) {
    values.ticketArray = [];
  }

  if (deviceOptions.length == 1) {
    values.fk_id_device = deviceOptions[0].value;
    form.fk_id_device = deviceOptions[0].value;
  }

  return ticketavaliation == true ? (
    <Dialog
      onClose={onClose}
      title={language.PENDING_TICKET_LIST}
      maxWidth="lg"
      open={open}
    >
      <Avaliation
        store={store}
        action={action}
        APIEndpoints={APIEndpoints}
      ></Avaliation>
    </Dialog>
  ) : openKnowledge == true ? (
    <Dialog
      title={language.KNOWLEDGE_BASE}
      open={openKnowledge}
      maxWidth="lg"
      contentProps={{
        style: { marginLeft: -50, marginRight: -20 },
      }}
      onClose={() => setOpenKnowledge(false)}
    >
      <SolutionContainer
        company={form.fk_id_company}
        subcategory={form.fk_id_catalog_service}
      />
    </Dialog>
  ) : (
    <Dialog
      PaperProps={{
        id: 'TicketManagementDialogForm',
      }}
      open={open}
      onClose={onClose}
      contentTitle={{
        style: { width: '100%' },
      }}
      btnCloseProps={{
        style: {
          marginTop: -88,
        },
      }}
      keepOpen={true}
      titleContentProps={{
        style: {
          width: 'calc(100% - 50px)',
        },
      }}
      maxWidth="xl"
      title={
        <div className="w-100 bar mt-2">
          <div className="d-flex align-items-start">
            <div className="w-100">
              <CommonForm
                values={{
                  subject: form.subject,
                }}
                alert={false}
                fields={[
                  {
                    type: 'text',
                    name: 'subject',
                    label: language.TICKET_SUBJECT,
                    className: 'subject-ticket',
                    required: true,
                  },
                ]}
                onRef={(r) => setForms1(r)}
                onChangeField={(f, v) => setForm({ subject: v })}
              />
            </div>
          </div>
          <div className="d-flex mt-3 mb-1">
            <div id={'techform'} style={{ width: '50%' }}>
              <CommonForm
                values={{
                  fk_id_tech: form.fk_id_tech,
                }}
                alert={false}
                onRef={(r) => setForms2(r)}
                onChangeField={(f, v) => {
                  setForm({ [f.name]: v });
                }}
                fields={[
                  {
                    span: 12,
                    type: 'autocomplete',
                    name: 'fk_id_tech',
                    label: language.TECH,
                    textlabel: language.TECH,

                    className: 'tech-group-ticket',
                    required: true,
                    method: 'POST',
                    route: `${APIEndpoints.USER}/filter`,
                    routeGetCount: `${APIEndpoints.USER}/active/count/all`,
                    filterAll: {
                      role: `tech`,
                      fk_id_group_users: form.fk_id_group_tech || 0,
                    },
                    fieldquery: 'search',
                    textinit: language.INVOLVED_TECH,
                    fieldvaluedb: 'id_user',
                    fieldlabeldb: 'name',
                    idinit: '',
                    routeAll: `${APIEndpoints.USER}/filter/all?`,
                    detail: true,
                    methodGetAll: 'POST',
                    showEmail: true,
                    size: 'small'
                  },
                ]}
              />
            </div>
            <div id={'grouptech'} style={{ width: '50%' }}>
              <CommonForm
                values={{
                  fk_id_group_tech: form.fk_id_group_tech,
                }}
                alert={false}
                onRef={(r) => setForms2(r)}
                onChangeField={(f, v) => {
                  if (f.name == 'fk_id_group_tech') {
                    setForm_({ id_grouptech: v });
                    if (values.OpenCategorize == false) {
                      // if feito para que os dados iniciais sejam carregados sem que seja gerado um bug ao setar um grupo tecnico
                      setForm({ fk_id_tech: null });
                    }
                  } else {
                  }

                  setForm({ [f.name]: v });
                }}
                fields={[
                  {
                    span: 12,
                    type: 'autocomplete',
                    name: 'fk_id_group_tech',

                    textlabel: language.TECH_GROUP,
                    className: 'tech-group-ticket',
                    required: true,
                    filter: { type_search: 1 },
                    filterAll: { type_search: 1, fromCommonTable: true},
                    method: 'POST',
                    route: `${APIEndpoints.GROUP}/filter`,
                    fieldquery: 'search',
                    textinit: (groupcat && groupcat.name) || '',
                    idinit:
                      (groupcat && parseInt(groupcat.id_group_users)) || 0,
                    fieldvaluedb: 'id_group_users',
                    fieldlabeldb: 'name',
                    useinit: true,
                    routeAll: `${APIEndpoints.GROUP}/all?`,
                    detail: true,
                    methodGetAll: 'POST',
                    routeGetCount: `${APIEndpoints.GROUP}/count`,
                    textinit:
                      (groupcat && groupcat.name) || language.TECH_GROUP,
                    textlabel: language.TECH_GROUP,
                    visible:
                      values.has_multi_ticket === true
                        ? values.has_multi_ticket !== true ||
                        keepOriginalCatalog.keep == false
                        : values.ticketArray.length == 0 ||
                        (values.ticketArray.length > 0 &&
                          keepOriginalCatalog.keep == false),
                    required:
                      values.ticketArray.length == 0 ||
                      (values.ticketArray.length > 0 &&
                        keepOriginalCatalog.keep == false),
                  },
                ]}
              />
            </div>
          </div>
        </div>
      }
    >
      <div className="d-flex justify-content-space-between type-group">
        <div>
          <span
            style={{
              fontSize: 10,
              color: 'var(--primary)',
              fontWeight: 'bold',
            }}
          >
            {language.SELECT_TICKET_TYPE}
          </span>
          <div className="d-flex align-items-left btn-group">
            <Button
              onClick={() => setForm({ type_tickets: 4 })}
              icon="fas fa-user-plus"
              label={language.TICKET_TYPES.SOLICITATION}
              className="btn-item"
              outlined={form.type_tickets != 4}
              color={form.type_tickets == 4 ? 'warning' : 'primary'}
              variant="normal"
            />
            <Button
              onClick={() => setForm({ type_tickets: 1 })}
              icon="fas fa-bell"
              label={language.TICKET_TYPES.INCIDENT}
              className="btn-item"
              outlined={form.type_tickets != 1}
              color={form.type_tickets == 1 ? 'warning' : 'primary'}
              variant="normal"
            />
            <Button
              onClick={() => setForm({ type_tickets: 2 })}
              icon="fas fa-exclamation-triangle"
              style={{ borderLeft: 0 }}
              label={language.TICKET_TYPES.PROBLEM}
              className="btn-item"
              outlined={form.type_tickets != 2}
              color={form.type_tickets == 2 ? 'warning' : 'primary'}
              variant="normal"
            />
            {generalParams.enable_event_ticket_type && (
              <Button
                onClick={() => setForm({ type_tickets: 5 })}
                icon="fas fa-clipboard"
                style={{ borderLeft: 0 }}
                label={language.TICKET_TYPES.EVENT}
                className="btn-item"
                outlined={form.type_tickets != 5}
                color={form.type_tickets == 5 ? 'warning' : 'primary'}
                variant="normal"
              />
            )}
            {enableTypeTicketCrisis && (
              <Button
                onClick={() => setForm({ type_tickets: 6 })}
                icon="fas fa-arrow-down"
                style={{ borderLeft: 0 }}
                label={language.TICKET_TYPES.CRISIS}
                className="btn-item"
                outlined={form.type_tickets != 6}
                color={form.type_tickets == 6 ? 'warning' : 'primary'}
                variant="normal"
              />
            )}
            {generalParams.enable_critical_incident_ticket_type && (
              <Button
                onClick={() => setForm({ type_tickets: 7 })}
                icon="fas fa-exclamation-circle"
                style={{ borderLeft: 0 }}
                label={language.TICKET_TYPES.CRITICAL_INCIDENT}
                className="btn-item"
                outlined={form.type_tickets != 7}
                color={form.type_tickets == 7 ? 'warning' : 'primary'}
                variant="normal"
              />
            )}
          </div>
          {values.ticketArray && values.ticketArray.length > 0 ? (
            <span
              style={{
                fontSize: 12,
                color: 'var(--primary)',
                fontWeight: 'bold',
              }}
            >
              Solicitaçoes {values.ticketArray.map((h) => 'R' + h).join(' ')}
            </span>
          ) : null}
          {values.ticketArray && values.ticketArray.length > 0 ? (
            <CommonForm
              fields={[
                {
                  type: 'check',
                  label: language.MAINTAIN_ORIGINAL_CATALOG_OF_REQUESTS,
                  name: 'keep',
                  col: 12,
                },
              ]}
              values={keepOriginalCatalog}
              onChangeField={(f, v) => {
                setKeepOriginalCatalog({ keep: !keepOriginalCatalog.keep });
              }}
            />
          ) : null}
        </div>
        <div className="d-flex align-items-rigth btn-group">
          {values && values.has_multi_ticket && (
            <Button
              variant="normal"
              label={language.MULTI_CALL}
              color="primary"
              tooltipLabel={
                language.THIS_TICKET_WAS_CREATED_USING_MULTI_TICKETS
              }
              style={{ marginTop: 27, marginRight: 20 }}
              disabled={true}
            />
          )}
          <Button
            variant="normal"
            icon={<KnowledgeBase style={{ fill: 'white', width: 20 }} />}
            label={language.KNOWLEDGE_BASE}
            color="warning"
            style={{ marginTop: 27 }}
            disabled={knowledgeBaseSubcategory ? false : true}
            fluid
            onClick={
              () => setOpenKnowledge(true) /*redirect('/dash/solution')*/
            }
          />
        </div>
      </div>
      <hr />

      <div style={{ zoom: 0.9 }} zoom={0.9}>
        <Tooltip
          style={{ marginLeft: '65%' }}
          interactive
          html={
            <div style={{ width: 350 }}>
              <GC
                valueProps={{
                  className: 'd-flex align-items-center',
                }}
                items={[
                  {
                    label: language.EMAIL,
                    value: userDetail?.email || '',
                  },
                  {
                    label: language.NAME,
                    value: userDetail?.name || '',
                  },
                  {
                    label: language.DEPARTMENT,
                    value: userDetail?.department || '',
                  },
                  {
                    label: language.PHONE,
                    value: userDetail?.phone || '',
                  },
                ]}
              />
            </div>
          }
          arrow={true}
          position="left"
          theme="light"
        >
          <i style={{ color: '#019fc2' }} class="fas fa-info"></i>
        </Tooltip>
        <CommonForm
          spacing={3}
          values={form}
          alert={false}
          fields={[
            {
              col: 4,
              type: 'autocomplete',
              name: 'fk_id_company',
              label: language.COMPANY,
              method: 'POST',
              route: `${APIEndpoints.COMPANY}/filter`,
              fieldquery: 'search',
              textlabel: language.COMPANY,
              textinit:
                values.fk_id_request > 0
                  ? values.name_company
                  : language.COMPANY,
              fieldvaluedb: 'id_company',
              fieldlabeldb: 'name',
              idinit: values.fk_id_company,
              routeAll: `${APIEndpoints.COMPANY}/all?${params[0].list_only_customer_type_companies ? 'type=1&' : ''}`,
              methodGetAll: 'POST',
              routeGetCount: `${APIEndpoints.COMPANY}/count`,
              detail: true,
              visible: values.ticketArray.length > 1 ? false : true,
              required: values.ticketArray.length > 1 ? false : true,
              /*  col: 4,
                  type: 'select',
                  name: 'fk_id_company',
                  label: 'Empresa',
                  options: companyOptions,
                  visible: values.ticketArray.length > 1 ? false : true,
                  required: true*/
            },
            {
              col: 4,
              type: 'autocomplete',
              name: 'fk_id_user',
              label: language.SOLICITANT,
              textlabel: language.SOLICITANT,
              method: 'POST',
              filter: { all_fk_id_company: form.fk_id_company || 0 },
              route: `${APIEndpoints.USER}/filter`,
              routeGetCount: `${APIEndpoints.USER}/active/count/${form.fk_id_company || 0}`,
              fieldquery: 'search',
              textinit:
                values.fk_id_request > 0
                  ? values.solicitant
                  : language.SOLICITANT,
              fieldvaluedb: 'id_user',
              fieldlabeldb: 'name',
              showvip: true,
              idinit: form.fk_id_user,
              routeAll: `${APIEndpoints.USER}/filter/all?`,
              filterAll: {
                all_fk_id_company: form.fk_id_company || 0,
                search_by_email: true,
              },
              methodGetAll: 'POST',
              detail: true,
              visible: values.ticketArray.length > 1 ? false : true,
              required: values.ticketArray.length > 1 ? false : true,
              cleanValue: cleanValueSolicitant,
              showEmail: true,

              /*  col: 4,
                  type: 'select',
                  name: 'fk_id_user',
                  label: 'Solicitante',
                  options: solicitantOptions,
                  detail: true,
                  visible: values.ticketArray.length > 1 ? false : true,
                  required: values.ticketArray.length > 1 ? false : true,
      */
            },

            {
              col: 4,
              type: 'select',
              name: 'fk_id_contract',
              label: language.CONTRACT_MANAGEMENT,
              options: contractOptions,
              required: true,
            },

            {
              col: 4,
              type: 'autocomplete',
              name: 'devices',
              label: language.DEVICE_CONF,
              textlabel: language.DEVICE_CONF,
              method: 'POST',
              route: `${APIEndpoints.DEVICE}/filter/`,
              routeGetCount: `${APIEndpoints.DEVICE}/filter?count=0`,
              fieldquery: 'search',
              textinit:
                values && values.devices_names ? values.devices_names : '',
              fieldvaluedb: 'id_device',
              fieldlabeldb: 'name',
              idinit: values && values.devices_ids ? values.devices_ids : '',
              routeAll: `${APIEndpoints.DEVICE}/filter?`,
              filterAll: {
                fk_id_company: form.fk_id_company
              },
              methodGetAll: 'POST',
              isMulti: true,
              detail: true,
            },

            {
              col: 4,
              type: 'select',
              name: 'fk_id_category',
              options: categories
                .filter(
                  (c) =>
                    c.type_ticket && c.type_ticket.includes(form.type_tickets)
                )
                .map((ctr) => ({ value: ctr.id_category, label: ctr.name })),
              label: language.CATEGORY,
              required:
                values.has_multi_ticket === true
                  ? values.has_multi_ticket !== true ||
                  keepOriginalCatalog.keep == false
                  : values.ticketArray.length == 0 ||
                  (values.ticketArray.length > 0 &&
                    keepOriginalCatalog.keep == false),
              visible:
                values.has_multi_ticket === true
                  ? values.has_multi_ticket !== true ||
                  keepOriginalCatalog.keep == false
                  : values.ticketArray.length == 0 ||
                  (values.ticketArray.length > 0 &&
                    keepOriginalCatalog.keep == false),
            },
            {
              col: 4,
              type: 'select',
              name: 'fk_id_catalog_service',
              label: language.SUBCATEGORY,
              options: serviceCatalogOptions,
              required:
                values.has_multi_ticket === true
                  ? values.has_multi_ticket !== true ||
                  keepOriginalCatalog.keep == false
                  : values.ticketArray.length == 0 ||
                  (values.ticketArray.length > 0 &&
                    keepOriginalCatalog.keep == false),
              visible:
                values.has_multi_ticket === true
                  ? values.has_multi_ticket !== true ||
                  keepOriginalCatalog.keep == false
                  : values.ticketArray.length == 0 ||
                  (values.ticketArray.length > 0 &&
                    keepOriginalCatalog.keep == false),
            },
            {
              col: 4,
              type: 'select',
              name: 'fk_id_catalog_task',
              label: language.THIRD_LEVEL_CATEGORY,
              options: tasksOptions,
              required:
                values.has_multi_ticket === true
                  ? values.has_multi_ticket !== true ||
                  keepOriginalCatalog.keep == false
                  : values.ticketArray.length == 0 ||
                  (values.ticketArray.length > 0 &&
                    keepOriginalCatalog.keep == false),
              visible:
                values.has_multi_ticket === true
                  ? values.has_multi_ticket !== true ||
                  keepOriginalCatalog.keep == false
                  : values.ticketArray.length == 0 ||
                  (values.ticketArray.length > 0 &&
                    keepOriginalCatalog.keep == false),
            },

            {
              col: 4,
              type: 'select',
              name: 'fk_id_urgency',
              label: language.URGENCY,
              options: [
                { value: 24, label: language.LOW },
                { value: 25, label: language.MEDIUM },
                { value: 26, label: language.HIGH },
                { value: 40, label: language.TICKET_PRIORITY.CRITICAL },
              ],
              visible: (params[0].allow_customize_priority && form.type_tickets !== 6) ? true : false,
              required: false,
            },

            {
              col: 4,
              type: 'select',
              name: 'fk_id_department',
              label: language.DEPARTMENT,
              options: departmentOptions,
              required: false,
            },
            {
              col: 4,
              type: 'autocomplete',
              name: 'fk_id_user_sponsor',
              label: language.RESPONSIBLE,
              textlabel: language.RESPONSIBLE,
              method: 'POST',
              filter: { all_fk_id_company: form.fk_id_company || 0 },
              route: `${APIEndpoints.USER}/filter`,
              routeGetCount: `${APIEndpoints.USER}/active/count/${form.fk_id_company || 0
                }`,

              fieldquery: 'search',
              textinit: form.fk_id_user_sponsor > 0 ? form.namesponsor : '',
              fieldvaluedb: 'id_user',
              fieldlabeldb: 'name',
              idinit:
                form.fk_id_user_sponsor > 0 ? form.fk_id_user_sponsor : null,
              routeAll: `${APIEndpoints.USER}/filter/all?`,
              filterAll: { all_fk_id_company: form.fk_id_company || 0 },
              methodGetAll: 'POST',
              detail: true,
              visible: values.ticketArray.length > 1 ? false : true,
              required: false,
              cleanValue: cleanValueSolicitant,
              showEmail: true,
            },
            {
              col: 4,
              type: 'select',
              name: 'fk_id_location',
              label: language.LOCATION_TITLE,
              options: locationOptions || [],
              required: false,
              visible: params[0].enable_location_in_opening_ticket
            },
            {
              type: 'element',
              content: <hr />,
            },
            {
              type: 'select',
              col: 6,
              name: 'recurrent',
              label: language.RECURRENCE,
              options: [
                { label: language.RECURRENCE_TYPE.NO_RECURRENCE, value: 1 },
                { label: language.RECURRENCE_TYPE.DAILY, value: 2 },
                { label: language.RECURRENCE_TYPE.WEEKLY, value: 3 },
                { label: language.RECURRENCE_TYPE.MONTHLY, value: 4 },
                { label: language.RECURRENCE_TYPE.QUARTERLY, value: 5 },
                { label: language.RECURRENCE_TYPE.SEMESTER, value: 6 },
                { label: language.RECURRENCE_TYPE.YEARLY, value: 7 },
                { label: language.SCHEDULING, value: 8 },
              ],
              disabled: form.type_tickets == 6
            },
            {
              col: 6,
              name: 'fk_id_channel',
              label: language.CHANNEL,
              type: 'select',
              required: generalParams.enable_requirement_channel_ticket,
              options: channel?.map((ch) => ({
                value: ch.id_channel,
                label: translateFromPtBR(ch.name, language),
                is_attachment_required: ch.is_attachment_required,
              })),
            },
            {
              col: 6,
              name: 'dt_start_crysis',
              label: language.SOLICITATION_DATE,
              type: 'date',
              required: true,
              visible: form.type_tickets == 6,
            },
            {
              col: 6,
              name: 'dt_starttime_crysis',
              label: language.SOLICITATION_HOUR,
              type: 'time',
              required: true,
              visible: form.type_tickets == 6,
            },
            {
              col: 6,
              name: 'documented_crisis',
              label: language.TICKET_TYPES.DOCUMENTED_CRISIS,
              type: 'check',
              required: true,
              visible: form.type_tickets == 6 && totalGroupDocumentedCrisis > 0,
            },
            {
              col: 6,
              name: 'dt_recurrence',
              label: language.RECURRENCE_DATE,
              type: 'date',
              required: true,
              visible: form.recurrent > 1,
            },
            {
              col: 6,
              name: 'time_recurrence',
              label: language.HOUR,
              type: 'time',
              required: true,
              visible: form.recurrent > 1,
            }
          ]}
          onRef={(r) => setForms3(r)}
          onChangeField={(f, v) => {
            if (f.name === 'fk_id_channel') {
              const isAttachmentRequired = f.options.filter((option) => option.value === v)[0].is_attachment_required;
              setAttachIsRequired(isAttachmentRequired);
            }

            if (f.name == 'fk_id_user') {
              userDetailFunc(v);
              setRequesterId(v);
              deviceContractSolicitant(undefined, v);
              setCleanValueSolicitant(false);
              chiefDepartment(v);
              findLocation(v);
              // setForm({ fk_id_location: 3424 });
            }

            if (f.name == 'fk_id_contract') {
              setForm_({ contract: v });
              deviceContractSolicitant(v, undefined);
              // chiefDepartment(form_.user);
            }

            if (f.name == 'fk_id_company') {
              setForm_({ company: v });
              catalogCompany(v);
              // categoryCompany(v);
              setCompanyId(v);
              setForm({ fk_id_user: undefined, fk_id_location: undefined });
              setCleanValueSolicitant(true);
              companyallContract(v);
            }

            if (f.name == 'fk_id_device') {
              setForm_({ device: v });
            }

            if (f.name == 'fk_id_category') {
              if (form.hasScript && !!form.description)
                setForm({ description: '', hasScript: false });

              setForm_({ category: v });
              catalogCompany(form_.company, v);
            }

            if (f.name == 'fk_id_catalog_service') {
              setKnowledgeBaseSubcategory(
                String(
                  f.options.filter((option) => option.value === v)[0].label
                ).trim()
              );

              if (form.hasScript && !!form.description)
                setForm({ description: '', hasScript: false });

              setForm_({ catalog: v });
              catalogGroup(v);
            }

            if (f.name == 'fk_id_catalog_task') {
              const selectedTask = catalogs
                .filter((item) => item.id_catalog_service === catalog)[0]
                .task.filter((item) => item.id_catalog_task === v)[0];
              const description = selectedTask?.script;

              if (description && form.description !== description)
                setForm({ description: description, hasScript: true });

              setForm_({ catalog_task: v });
              getFormCatalog(v);
            }
            if (f.name == 'fk_id_group_tech') {
              setForm_({ id_grouptech: v });
            }
            if (f.name == 'fk_id_urgency') {
              setForm_({ fk_id_urgency: v });
              setForm({ fk_id_urgency: v });
            }

            if (f.name == 'fk_id_department') {
              setForm_({ iddep: v });
            }
            if (f.name == 'recurrent') {
              setForm({ recurrent: v });
            }
            setForm({ [f.name]: v });

            if (f.name == 'fk_id_user' && v) {
              chiefDepartment(v);
            }
          }}
        />
      </div>

      <hr />
      <span
        style={{ fontSize: 10, color: 'var(--primary)', fontWeight: 'bold' }}
      >
        {language.ATTACHMENT}
      </span>

      <div>
        <Grid container spacing={16}>
          <Grid item xs={6}>
            <CommonForm
              onChangeField={(f, v) => {
                setForm({ [f.name]: v });
              }}
              values={form}
              fields={[
                {
                  col: 12,
                  type: 'file',
                  name: 'files',
                  label: language.ATTACH,
                  required: attachIsRequired,
                  errorAttach: attachmentError
                },
              ]}
            ></CommonForm>
          </Grid>
          <Grid item xs={6} style={{ marginTop: '-10px' }}>
            {form.filesrequest.length > 0 && (
              <CommonForm
                onChangeField={(f, v) => {
                  setForm({ [f.name]: v });
                }}
                values={form}
                fields={[
                  {
                    col: 12,
                    type: 'file',
                    name: 'filesrequest',
                    label: language.REQUEST_ATTACHMENTS,
                    btn: true,
                    onClick: (f) => ondonwFile(f.id_request_file, f.nn),
                    required: attachIsRequired,
                  },
                ]}
              />
            )}
          </Grid>
        </Grid>
      </div>
      <hr />
      <div>
        <div>
          <span
            style={{
              fontSize: 10,
              color: 'var(--primary)',
              fontWeight: 'bold',
            }}
          >
            {language.DESCRIPTION + '*'}
          </span>
        </div>

        <div>
          <CommonForm
            key={form.hasScript}
            onChangeField={(f, v) => {
              setForm({ description: v });
            }}
            alert={false}
            values={form}
            fields={[
              values.fk_id_request > 0
                ? // {
                //     type: 'element',
                //     content: (
                //       <DescriptionRequest
                //         fk_id_request={values.fk_id_request}
                //         APIEndpoints={APIEndpoints}
                //         tokenApi={store.getToken()}
                //       ></DescriptionRequest>
                //     ),
                //   }
                {
                  type: params?.[0]?.integration ? 'simplesTextEditor' : 'textEditor',
                  name: 'description',
                  placeholder: language.DESCRIPTION,
                  required: true,
                  disabled: true,
                  visible:
                    values.ticketArray.length == 0 ||
                    (values.ticketArray.length > 0 &&
                      keepOriginalCatalog.keep == false),
                }
                : {
                  type: params?.[0]?.integration ? 'simplesTextEditor' : 'textEditor',
                  name: 'description',
                  placeholder: language.DESCRIPTION,
                  required: true,
                },
            ]}
            onRef={(r) => setForms4(r)}
          />
        </div>
      </div>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open_}
        onClose={handleClose}
      >
        {groupstech &&
          groupstech
            .sort(function (a, b) {
              return a.name - b.name;
            })
            .filter((g) => g.fk_id_group_users == groutechfk)
            .map((tec) => (
              <MenuItem
                onClick={() => {
                  setForm({ fk_id_tech: tec.id_user });
                  setTechname(tec.name);
                  handleClose();
                }}
              >
                <i className="fas" style={{ color: 'var(--primary)' }} />
                <span
                  style={{ fontSize: 15, color: 'var(--primary)' }}
                  className="ml-2"
                >
                  {tec.name}
                </span>
              </MenuItem>
            ))}
      </Menu>

      <hr />

      {templateFormOriginal && templateFormOriginal !== null ? (
        generalParams.allow_edit_form_categorization ? (
          <div className="itemTicketDetail ">
            <p
              style={{
                color: '#888080',
              }}
              id={'template'}
            >
              {template.length > 0 ? template[0].html : null}
            </p>
          </div>
        ) : (
          <p
            className="template template-dinamic"
            style={{
              color: '#888080',
              pointerEvents: !values.html_values ? 'auto' : 'none',
            }}
            id={'template'}
            dangerouslySetInnerHTML={{
              __html: template.length > 0 ? template[0].html : null,
            }}
          />
        )
      ) : forms ? (
        <div id="template" style={{ marginTop: '10px' }} className="template">
          {forms}
        </div>
      ) : (
        <div id="template" className="template">
          {template.length > 0 ? template[0].html : null}
        </div>
      )}
      <hr />
      <Button
        icon="fas fa-save"
        variant="normal"
        color="success"
        fluid
        onClick={() => (ds == true ? onFormSubmitTab_() : null)}
        loading={isProcessingSave}
        style={{ height: 40 }}
        label={language.SAVE}
      />
    </Dialog>
  );
}